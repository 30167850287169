import React, { PureComponent } from "react";
import './StartButton.css';
import MainGame from './MainGame'
import title from '../images/title.png'

class StartButton extends PureComponent<{}, {startVisible: boolean, mainGameVisible: boolean, pics: any, localStoredPics: any}> {
	constructor(props: any) {
    super(props);
    this.state = { 
      startVisible: true,
      mainGameVisible: false,
      pics: 'none',
      localStoredPics: '',
    };
  }

  //http://localhost:3000/pictures/getGameSessionPics
  //https://genshin-geoguessr-349101.ue.r.appspot.com/pictures/getGameSessionPics
  componentDidMount(){
    this.setState({
      localStoredPics:
      [
    {
        "_id": "6270ad760879b462c85c2df5",
        "name": "pic19.jpg",
        "lat": "1989",
        "lng": "1410",
        "location": "Teyvat"
    },
    {
        "_id": "6271580f77631020632c22ab",
        "name": "pic32.jpg",
        "lat": "925",
        "lng": "2466",
        "location": "Teyvat"
    },
    {
        "_id": "605fe65bcce2e02244237979",
        "name": "pic3.jpg",
        "lat": "2500",
        "lng": "2219",
        "location": "Teyvat"
    },
    {
        "_id": "60cfed62dc531b2ff46841ae",
        "name": "pic6.jpg",
        "lat": "2492",
        "lng": "2134",
        "location": "Teyvat"
    },
    {
        "_id": "6270c1490879b462c85c2df9",
        "name": "pic23.jpg",
        "lat": "1606",
        "lng": "1533",
        "location": "Teyvat"
    },
    {
        "_id": "6270a8810879b462c85c2df4",
        "name": "pic18.jpg",
        "lat": "1973",
        "lng": "1853",
        "location": "Teyvat"
    },
    {
        "_id": "62715eb077631020632c22ac",
        "name": "pic33.jpg",
        "lat": "884",
        "lng": "2071",
        "location": "Teyvat"
    },
    {
        "_id": "62717184c2c3c138e9fdc9ee",
        "name": "pic37.jpg",
        "lat": "284",
        "lng": "2446",
        "location": "Teyvat"
    },
    {
        "_id": "627073e40879b462c85c2dec",
        "name": "pic10.jpg",
        "lat": "2200",
        "lng": "1641",
        "location": "Teyvat"
    },
    {
        "_id": "627090330879b462c85c2df1",
        "name": "pic15.jpg",
        "lat": "2213",
        "lng": "1576",
        "location": "Teyvat"
    },
    {
        "_id": "627153da77631020632c22aa",
        "name": "pic31.jpg",
        "lat": "849",
        "lng": "2373",
        "location": "Teyvat"
    },
    {
        "_id": "627166d077631020632c22ae",
        "name": "pic35.jpg",
        "lat": "644",
        "lng": "2840",
        "location": "Teyvat"
    },
    {
        "_id": "6270b3ad0879b462c85c2df7",
        "name": "pic22.jpg",
        "lat": "1814",
        "lng": "1597",
        "location": "Teyvat"
    },
    {
        "_id": "627086d40879b462c85c2df0",
        "name": "pic14.jpg",
        "lat": "2140",
        "lng": "1834",
        "location": "Teyvat"
    },
    {
        "_id": "62716a1a77631020632c22af",
        "name": "pic36.jpg",
        "lat": "165",
        "lng": "2438",
        "location": "Teyvat"
    },
    {
        "_id": "627077080879b462c85c2ded",
        "name": "pic11.jpg",
        "lat": "2180",
        "lng": "1813",
        "location": "Teyvat"
    },
    {
        "_id": "60331bb27611d23c38d917bd",
        "name": "pic1.jpg",
        "lat": "2307",
        "lng": "2089",
        "location": "Teyvat"
    },
    {
        "_id": "6271737cc2c3c138e9fdc9ef",
        "name": "pic38.jpg",
        "lat": "412",
        "lng": "761",
        "location": "The_Chasm"
    },
    {
        "_id": "6270b4380879b462c85c2df8",
        "name": "pic21.jpg",
        "lat": "1711",
        "lng": "1977",
        "location": "Teyvat"
    },
    {
        "_id": "6271797a59e2b4472e3f2656",
        "name": "pic40.jpg",
        "lat": "945",
        "lng": "1185",
        "location": "Enkanomiya"
    },
    {
        "_id": "62712be577631020632c22a8",
        "name": "pic29.jpg",
        "lat": "991",
        "lng": "2968",
        "location": "Teyvat"
    },
    {
        "_id": "627151c477631020632c22a9",
        "name": "pic30.jpg",
        "lat": "860",
        "lng": "2578",
        "location": "Teyvat"
    },
    {
        "_id": "62711e5f77631020632c22a5",
        "name": "pic26.jpg",
        "lat": "1607",
        "lng": "1338",
        "location": "Teyvat"
    },
    {
        "_id": "62712a1b77631020632c22a7",
        "name": "pic28.jpg",
        "lat": "1201",
        "lng": "2853",
        "location": "Teyvat"
    },
    {
        "_id": "60cfedb7dc531b2ff46841b1",
        "name": "pic9.jpg",
        "lat": "2424",
        "lng": "1782",
        "location": "Teyvat"
    },
    {
        "_id": "62707d210879b462c85c2dee",
        "name": "pic12.jpg",
        "lat": "2128",
        "lng": "1983",
        "location": "Teyvat"
    },
    {
        "_id": "627125f077631020632c22a6",
        "name": "pic27.jpg",
        "lat": "1659",
        "lng": "1709",
        "location": "Teyvat"
    },
    {
        "_id": "62711a2d77631020632c22a4",
        "name": "pic25.jpg",
        "lat": "1655",
        "lng": "1345",
        "location": "Teyvat"
    },
    {
        "_id": "60cfed7fdc531b2ff46841af",
        "name": "pic7.jpg",
        "lat": "2438",
        "lng": "2186",
        "location": "Teyvat"
    },
    {
        "_id": "6270afb90879b462c85c2df6",
        "name": "pic20.jpg",
        "lat": "2155",
        "lng": "1431",
        "location": "Teyvat"
    },
    {
        "_id": "605fe63fcce2e02244237977",
        "name": "pic2.jpg",
        "lat": "2147",
        "lng": "2308",
        "location": "Teyvat"
    },
    {
        "_id": "6271607077631020632c22ad",
        "name": "pic34.jpg",
        "lat": "619",
        "lng": "2720",
        "location": "Teyvat"
    },
    {
        "_id": "6270a3420879b462c85c2df3",
        "name": "pic17.jpg",
        "lat": "2075",
        "lng": "1699",
        "location": "Teyvat"
    },
    {
        "_id": "627083380879b462c85c2def",
        "name": "pic13.jpg",
        "lat": "2055",
        "lng": "2004",
        "location": "Teyvat"
    },
    {
        "_id": "627098160879b462c85c2df2",
        "name": "pic16.jpg",
        "lat": "1907",
        "lng": "1895",
        "location": "Teyvat"
    },
    {
        "_id": "6271768959e2b4472e3f2655",
        "name": "pic39.jpg",
        "lat": "225",
        "lng": "118",
        "location": "The_Chasm"
    },
    {
        "_id": "6270c3660879b462c85c2dfa",
        "name": "pic24.jpg",
        "lat": "1754",
        "lng": "1443",
        "location": "Teyvat"
    },
    {
        "_id": "60c19df8768a5101a61fb677",
        "name": "pic5.jpg",
        "lat": "2172",
        "lng": "1947",
        "location": "Teyvat"
    },
    {
        "_id": "60cfed9adc531b2ff46841b0",
        "name": "pic8.jpg",
        "lat": "2285",
        "lng": "1834",
        "location": "Teyvat"
    },
    {
        "_id": "new1",
        "name": "pic41.jpg",
        "lat": "1704",
        "lng": "1134",
        "location": "Teyvat"
    },
    {
        "_id": "new2",
        "name": "pic42.jpg",
        "lat": "1868",
        "lng": "1219",
        "location": "Teyvat"
    },
    {
        "_id": "new3",
        "name": "pic43.jpg",
        "lat": "1753",
        "lng": "989",
        "location": "Teyvat"
    },
    {
        "_id": "new4",
        "name": "pic44.jpg",
        "lat": "1464",
        "lng": "1257",
        "location": "Teyvat"
    },
    {
        "_id": "new5",
        "name": "pic45.jpg",
        "lat": "1249",
        "lng": "1162",
        "location": "Teyvat"
    },
    {
        "_id": "new6",
        "name": "pic46.jpg",
        "lat": "1633",
        "lng": "840",
        "location": "Teyvat"
    },
    {
        "_id": "new7",
        "name": "pic47.jpg",
        "lat": "1335",
        "lng": "869",
        "location": "Teyvat"
    },
    {
        "_id": "new8",
        "name": "pic48.jpg",
        "lat": "1064",
        "lng": "701",
        "location": "Teyvat"
    },
    {
        "_id": "new9",
        "name": "pic49.jpg",
        "lat": "1056",
        "lng": "600",
        "location": "Teyvat"
    }
]
    });
  }

	clickPlay = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const shuffled = this.state.localStoredPics.sort(() => 0.5 - Math.random());
    let data = shuffled.slice(0, 5);
    this.setState({ pics: data },this.teste);
    /*
      fetch("http://localhost:3000/pictures/getGameSessionPics", {
      credentials: 'include',
      method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true'
        }
      }).then((response) => response.json())
        .then(data => {
          this.setState({ pics: data },this.teste);
      });;
      */
	};

  teste(){
    this.setState({ startVisible: false});
    this.setState({ mainGameVisible: true});
  }

  render() {
    const { startVisible, mainGameVisible } = this.state;
    let myStyle;

    if(!this.state.startVisible){
      myStyle = {
        display: 'none',
      };
    }

    return (

      <div className='maindiv'>

        {this.state.pics == 'none' ? (
          null
        ) : (
          <MainGame 
            pics={this.state.pics}
            visible={this.state.mainGameVisible}
          />
        )}
        
        <div className='MainArea' style={myStyle}>
        <div className='HomeArea'>
          <img className='HomeAreaImg' src={title}/>
          <h2>Do you have what it takes to <br/>find yourself in Teyvat?</h2>
          <button onClick={this.clickPlay} className='PlayButton'>Play</button>
        </div>
        <div className='credits'>Created by <div className="creator">SirFelpudo
          <span className="contactInfo">gabrieldqs@gmail.com</span>
        </div>
        <br/>Inspired by <a href="https://geoguessr.com/">geoguessr</a></div>
        </div>
      </div>
    );
  }
}

export default StartButton;