import React,  { useState } from "react";
import L from "leaflet";
import { Marker, useMapEvents} from 'react-leaflet'
import markerImage from "../images/questionMarker.png";


function LocationMarker() {
  const [position, setPosition] = useState(null)
	// @ts-ignore 
  const newicon = new L.icon({
    iconUrl:markerImage,
    iconSize: [40, 50],
    iconAnchor: [20, 40]
  })

	const map = useMapEvents({
		click(e) {
        // @ts-ignore 
        document.getElementById("guessButton").disabled = false
        //console.log(e.latlng)
      
        // @ts-ignore 
    	  setPosition(e.latlng)
    	}
  	})

       // @ts-ignore 
	return position === null ? null : (
    <Marker 
      // @ts-ignore
      position={position} 
      icon={newicon} 
      title='locationMarker'>
    </Marker>
  	)
}

export default LocationMarker;