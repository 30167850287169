import React,  { Component } from "react";
import L, { LatLngBounds } from "leaflet";
import { MapContainer, ImageOverlay} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import genshinFullMap from '../images/teyvatfullmap.png';
import genshinTheChasmMap from '../images/The_Chasm.png';
import genshinEnkanomiyaMap from '../images/Enkanomiya.png';
import LocationMarker from './LocationMarker';
// @ts-ignore 
import $ from "jquery";

interface mapComponentProps {
  selectedMap: string;
  locationMarkerVisible: boolean;
  lat: number;
  lng: number;
  zoom: number;
}

class MapComponent extends Component<mapComponentProps, {}> {
	private mapRef: any;
	private locationMarkerRef: any;

   	constructor(props: any) {
	    super(props);
	    this.mapRef = React.createRef()
	    this.locationMarkerRef = React.createRef()
	}

	componentDidMount() {
    if (! /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
  		$( ".mainMap" ).mouseenter(function() {
  			$(".mainMap").stop(true,true).animate({height:'+=140px',width:'+=140px'},0);
  			$("#selectArea").stop(true,true).animate({bottom:'+=140px'},0);
  		}).mouseleave(function() {
  		  $(".mainMap").stop(true,true).animate({height:'-=140px',width:'-=140px'},800);
  		  $("#selectArea").stop(true,true).animate({bottom:'-=140px'},800);
  		});
    }
  }

  	componentDidUpdate() {
  		setTimeout(() => this.mapRef.current.invalidateSize(false),100);
  	}

  	render() {
  	let map = this.mapRef.current
  	let panTo = new L.LatLng(2200, 1823)
  	let bounds = new LatLngBounds([0, 0], [2816, 3328])
  	let maxBounds = new LatLngBounds([0, 0], [2580, 3328])
  	let choosenMap = genshinFullMap
  	let minZoom = -1
  	switch (this.props.selectedMap) {
  		case "Enkanomiya":
  			panTo = new L.LatLng(850, 850)
  			minZoom = -1
  			choosenMap = genshinEnkanomiyaMap
  			bounds = new LatLngBounds([0, 0], [1700, 1700])
  		 	maxBounds =  new LatLngBounds([100 , 0], [1700, 1600])
  			break;
  		case "The_Chasm":
  		 	minZoom = 0
  			panTo = new L.LatLng(400, 350)
  			choosenMap = genshinTheChasmMap
  			bounds = new LatLngBounds([0, 0], [763, 898])
  			maxBounds =  new LatLngBounds([150, 0], [763, 788])
  			break;
  	}

  	if (map){
  		map.panTo(panTo)
  		map.setMaxBounds(maxBounds)
  		map.options.minZoom = minZoom
  	}


    return (
		<MapContainer
			className='mainMap'
			whenCreated={ mapInstance => { this.mapRef.current = mapInstance } }
		    center={{ lat: this.props.lat, lng: this.props.lng}}
		    zoom={this.props.zoom}
		    maxZoom={2}
		    minZoom={-1}
		    crs={L.CRS.Simple}
		    maxBounds={maxBounds}
		    scrollWheelZoom={true}>
		    <ImageOverlay
			  url={choosenMap}
			  bounds={bounds}
			  opacity={1}
			  zIndex={100}
			/>
			{this.props.locationMarkerVisible != false ? (
	          <LocationMarker/>
	        ) : (
	          null
	        )}
		</MapContainer>
		);
  	}
}

export default MapComponent;