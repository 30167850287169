import React,  { Component } from "react";
import L, { LatLngBounds } from "leaflet";
import { Polyline, Marker, MapContainer, ImageOverlay} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import genshinFullMap from '../images/teyvatfullmap.png';
import genshinTheChasmMap from '../images/The_Chasm.png';
import genshinEnkanomiyaMap from '../images/Enkanomiya.png';
import guessMarkerImage from "../images/questionMarker.png";
import actualMarkerImage from "../images/alertMarker.png";

interface scoreMapProps {
  guessLocation: string,
  actualLocation: string,
  score: number,
  guessMarkerLatLng: any,
  actualMarkerLatLng: any,
  round: number,
  totalScore: number,
}

class ScoreMap extends Component<scoreMapProps, {
	score: number, 
	zoomLevel: number,
	}> {
	private mapRef: any;
	private locationMarkerRef: any;
	private iconGuessMarker: L.Icon;
	private iconActualMarker: L.Icon;

   	constructor(props: any) {
	    super(props);
	    this.mapRef = React.createRef()
	    this.locationMarkerRef = React.createRef()
	    this.state = {
	    	score: this.props.score ? this.props.score : 0,
	    	zoomLevel: 1,
	    }

	    this.iconGuessMarker = L.icon({
		    iconUrl:guessMarkerImage,
		    iconSize: [40, 50],
		    iconAnchor: [20, 40]
	  	})

	  	this.iconActualMarker = L.icon({
		    iconUrl:actualMarkerImage,
		    iconSize: [40, 50],
		    iconAnchor: [20, 40]
	  	})
	}

	getPointsDistance(firstLatLng: any, secondLatLng: any){
		return Math.sqrt(Math.pow((secondLatLng.lat-firstLatLng.lat),2)+Math.pow((secondLatLng.lng-firstLatLng.lng),2))
	}

	getMiddlePoint(firstLatLng: any, secondLatLng: any) {
		if (this.getPointsDistance(firstLatLng,secondLatLng) > 400){
			return firstLatLng;
		}
		return new L.LatLng((firstLatLng.lat+secondLatLng.lat)/2,(firstLatLng.lng+secondLatLng.lng)/2)
	}

	getZoomLevel(firstLatLng: any, secondLatLng: any){
		if (this.props.guessLocation != this.props.actualLocation){
			return 1
		}

		let distance = this.getPointsDistance(firstLatLng,secondLatLng)

		if(distance<=40)
			return 3
		if(distance>40  && distance<=85)
			return 2
		if(distance>85 && distance<=155)
			return 1
		return 0
	}

  	componentDidUpdate() {
  		setTimeout(() => this.mapRef.current.invalidateSize(false),100);
  	}

  	render() {
  	var barWidth = Math.floor((this.state.score/5000)*100) + '%'

  	let map = this.mapRef.current
  	let panTo = new L.LatLng(2000, 750)
  	let bounds = new LatLngBounds([0, 0], [2816, 3328])
  	let maxBounds = new LatLngBounds([0, 0], [2580, 3328])
  	let choosenMap = genshinFullMap
  	let minZoom = -1
  	switch (this.props.actualLocation) {
  		case "Enkanomiya":
  			panTo = new L.LatLng(850, 850)
  			minZoom = -1
  			choosenMap = genshinEnkanomiyaMap
  			bounds = new LatLngBounds([0, 0], [1700, 1700])
  		 	maxBounds =  new LatLngBounds([100 , 0], [1700, 1600])
  			break;
  		case "The_Chasm":
  		 	minZoom = 0
  			panTo = new L.LatLng(400, 350)
  			choosenMap = genshinTheChasmMap
  			bounds = new LatLngBounds([0, 0], [763, 898])
  			maxBounds =  new LatLngBounds([150, 0], [763, 788])
  			break;
  	}

  	if (map){
  		map.panTo(panTo)
  		map.setMaxBounds(maxBounds)
  		map.options.minZoom = minZoom
  	}

    return (
    	<div>
	    	<div className='scoreOverlay'>
	    	<div className='mapConainter'>
	    	<div className="pointBarBorder">
            <div className="pointBar" style={{width: barWidth}}></div>
       		
       		</div>
				<MapContainer
					className='scoreMap'
					whenCreated={ mapInstance => { this.mapRef.current = mapInstance } }
				    center={this.getMiddlePoint(this.props.actualMarkerLatLng,this.props.guessMarkerLatLng)}
				    zoom={this.getZoomLevel(this.props.actualMarkerLatLng,this.props.guessMarkerLatLng)}
				    maxZoom={3}
				    crs={L.CRS.Simple}
				    maxBounds={maxBounds}
				    scrollWheelZoom={true}>
				    <ImageOverlay
					  url={choosenMap}
					  bounds={bounds}
					  opacity={1}
					  zIndex={100}
					/>
					<Marker 
				      position={this.props.actualMarkerLatLng} 
				      icon={this.iconActualMarker} 
				      title='locationMarker'>
				    </Marker>
				     {this.props.guessLocation == this.props.actualLocation ? 
				    <Polyline
				    	color='yellow'
				    	positions={[this.props.actualMarkerLatLng,this.props.guessMarkerLatLng]}
				    /> : null}
				    {this.props.guessLocation == this.props.actualLocation ? 				    
				    <Marker 
				      position={this.props.guessMarkerLatLng} 
				      icon={this.iconGuessMarker} 
				      title='locationMarker'>
				    </Marker>  : null}
				</MapContainer>
			</div>
	          	<div className='roundEndedText'>
	          		You got {this.state.score} points this round!
	          	</div>
			</div>
		</div>
		);
  	}
}

export default ScoreMap;