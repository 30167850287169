import React,  { Component } from "react";
import './MainGame.css';
import MapComponent from './MapComponent'
import ScoreMap from './ScoreMap'
import FinalScoreMap from './FinalScoreMap'
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import L, { Map , LatLng, LatLngBounds } from "leaflet";
import loadGif from '../images/photosphere-logo.gif'
// @ts-ignore 
import { Viewer, PhotoSphereViewer } from 'photo-sphere-viewer';

interface mainGameProps {
  visible: boolean;
  pics: any;
}

class MainGame extends Component<mainGameProps, {
  selectedMap: string, 
  sphereLoaded: boolean, 
  round: number, 
  totalScore: number,
  roundScore: number, 
  roundEnded: boolean,
  gameEnded: boolean,
  locationMarkerVisible: boolean,
  previousGuesses: any,
  mapZoomLevel: number,
  }> {

  private mapComponentRef: any;
  private photosphereContainerRef: any;

  constructor(props: any) {
    super(props);
    this.mapComponentRef = React.createRef();
    this.photosphereContainerRef = React.createRef();
    this.state = {
      selectedMap: "Teyvat",
      sphereLoaded: false,
      round: 1,
      totalScore: 0,
      roundScore: 0,
      roundEnded: false,
      locationMarkerVisible: true,
      gameEnded: false,
      previousGuesses: [],
      mapZoomLevel: 0,
    };
  }

  calcGuessScore(guessPosition: LatLng, realPosition: LatLng, guessLocation: string, realLocation: string) {
    let roundScore = 0

    if (guessLocation != realLocation){
      this.setState({roundScore: roundScore})
      this.setState({totalScore: this.state.totalScore + roundScore})
      this.setState({roundEnded: true})
      return
    }

    let distance = Math.sqrt(Math.pow((realPosition.lat-guessPosition.lat),2)+Math.pow((realPosition.lng-guessPosition.lng),2))

    if (distance <= 8){
      roundScore = 5000
    }
    else if (distance > 8 && distance < 200){
      roundScore = Math.floor((-26.041666667 * distance) + 5208.33333)
    }
    else if (distance >= 200){
      roundScore = 0
    }

    this.setState({roundScore: roundScore})
    this.setState({totalScore: this.state.totalScore + roundScore})
    this.setState({roundEnded: true})
  }

  loadPhotosphere() {

    const viewer = new Viewer({
      container: 'viewer',
      panorama: "https://f004.backblazeb2.com/file/cldimglt/"+this.props.pics[this.state.round-1].name,
      loadingImg: loadGif, 
      defaultLat: 0.3,
      touchmoveTwoFingers: true,
      mousewheelCtrlKey: true,
    });
    viewer.once('ready', () => {
      viewer.rotate({
        x: Math.random() * (8192 - 1) + 1,
        y: 2200
      });
    });
    if(document.getElementsByClassName("psv-container").length>1){
      document.getElementsByClassName("psv-container")[0].remove()

      var resizeEvent = window.document.createEvent('UIEvents'); 
      //@ts-ignore 
      resizeEvent.initUIEvent('resize', true, false, window, 0); 
      window.dispatchEvent(resizeEvent);
    }

    this.setState({sphereLoaded: true})  
  };

  deleteMarkers(){
    this.setState({locationMarkerVisible: false}, () => this.setState({locationMarkerVisible: true}) )
  }

  getLocationMarkerLatLng() {
    let latLng = 0

    this.mapComponentRef.current.mapRef.current.eachLayer(function(layer: any) {
      if(layer.options && layer.options.title === "locationMarker") {
        latLng =  layer._latlng
      }
    });
    return latLng
  }

  resetMapPos() {
    this.mapComponentRef.current.mapRef.current.panTo(new L.LatLng(2200, 1823))
    this.mapComponentRef.current.mapRef.current.setZoom(0)
  }

  clickGuess = (e: React.MouseEvent<HTMLButtonElement>): void => {
    let locationMarkerLatLng = this.getLocationMarkerLatLng();

    if(locationMarkerLatLng != 0){
      // @ts-ignore 
      this.calcGuessScore(L.latLng(locationMarkerLatLng.lat,locationMarkerLatLng.lng),L.latLng(this.props.pics[this.state.round-1].lat,this.props.pics[this.state.round-1].lng),this.state.selectedMap,this.props.pics[this.state.round-1].location)
    }
  };


  changeMap = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    let map = this.mapComponentRef.current.mapRef.current
    this.deleteMarkers()
    this.setState({selectedMap: e.target.value})
    switch (e.target.value) {
      case "Enkanomiya":
        this.setState({mapZoomLevel: -1})
        break;
      case "The_Chasm":
        this.setState({mapZoomLevel: 0})
        break;
      default:
        this.setState({mapZoomLevel: -1})
        break;
    }
  };


  clickNextRound = (e: React.MouseEvent<HTMLButtonElement>): void => { 
    this.resetMapPos()
    let auxPreviousGuesses = this.state.previousGuesses
    //@ts-ignore 
    auxPreviousGuesses.push([this.getLocationMarkerLatLng().lat,this.getLocationMarkerLatLng().lng, this.props.pics[this.state.round-1].location])
    this.setState({
      previousGuesses: auxPreviousGuesses,
      round: this.state.round + 1,
      roundScore: 0,
      roundEnded: false,
      sphereLoaded: false,
    })
    this.setState({locationMarkerVisible: false}, () => this.setState({locationMarkerVisible: true}) )
  };

  clickSeeResults = (e: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({
      roundEnded: false,
      gameEnded: true
    })
  };

  clickPlayAgain = (e: React.MouseEvent<HTMLButtonElement>): void => {
    window.location.reload();
  };

  componentDidUpdate() {
    //@ts-ignore 
    document.getElementById("guessButton")!.disabled = true

    if(this.state.sphereLoaded == false)
      this.loadPhotosphere();
  }

  render() {
    const { round } = this.state;
    let myStyle = {
      display: 'block',
    }

    if(!this.props.visible){
      myStyle = {
        display: 'none',
      };
    }

    return (
      <div className='MainGame' style={myStyle} >

        {this.state.roundEnded ? (
          <div>
            <ScoreMap 
              score={this.state.roundScore}
              guessLocation={this.state.selectedMap}
              actualLocation={this.props.pics[this.state.round-1].location}
              guessMarkerLatLng={this.getLocationMarkerLatLng()}
              actualMarkerLatLng={L.latLng(this.props.pics[this.state.round-1].lat,this.props.pics[this.state.round-1].lng)}
              round={this.state.round}
              totalScore={this.state.totalScore}
              />
              {this.state.round == 5 ? (
                <button onClick={this.clickSeeResults} className='nextRoundButton'>See Results</button>
              ) : (
                <button onClick={this.clickNextRound} className='nextRoundButton'>Play Next Round</button>
              )}
          </div>
        ) : (
          null
        )}
        
        <div>
        <select className="form-select" value={this.state.selectedMap} id="selectArea" onChange={this.changeMap}>
          <option value="Teyvat" selected>Teyvat</option>
          <option value="The_Chasm">The Chasm</option>
          <option value="Enkanomiya">Enkanomiya</option>
        </select>
        {this.state.gameEnded ? (
          <div>
            <FinalScoreMap 
              score={this.state.roundScore} 
              guessMarkerLatLng={this.getLocationMarkerLatLng()}
              actualMarkerLatLng={L.latLng(this.props.pics[this.state.round-1].lat,this.props.pics[this.state.round-1].lng)}
              actualLocation = {this.props.pics[this.state.round-1].location}
              round={this.state.round}
              totalScore={this.state.totalScore}
              previousGuesses={this.state.previousGuesses}
              pics={this.props.pics}
            />
            <button onClick={this.clickPlayAgain} className='nextRoundButton'>Play Again</button>
          </div>
        ) : (
          null
        )}
        </div>
        <div className='gameInfo'>
          <div className='roundScoreCountLabel'>round &emsp; score</div>
          <div className='roundScoreCount'>{this.state.round}/5 | {this.state.totalScore}</div>
        </div>
        <button onClick={this.clickGuess} id='guessButton'>Make Guess</button>
        <MapComponent 
          selectedMap={this.state.selectedMap}
          ref={ this.mapComponentRef} 
          locationMarkerVisible={this.state.locationMarkerVisible}
          zoom={this.state.mapZoomLevel}
          lat={2200}
          lng={1823}
        />

        <div id="viewer"></div>
        
      </div>
    );
  }
}

export default MainGame;
/*
      <select className="form-select" value={this.state.selectedMap} id="selectArea" onChange={this.changeMap}>
        <option value="Teyvat" selected>Teyvat</option>
        <option value="The_Chasm">The Chasm</option>
        <option value="Enkanomiya">Enkanomiya</option>
      </select> */