import React,  {Component } from "react";
import L, { LatLngBounds } from "leaflet";
import { Polyline, Marker, MapContainer, ImageOverlay} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import genshinFullMap from '../images/teyvatfullmap.png';
import genshinTheChasmMap from '../images/The_Chasm.png';
import genshinEnkanomiyaMap from '../images/Enkanomiya.png';
import guessMarkerImage from "../images/questionMarker.png";
import actualMarkerImage from "../images/alertMarker.png";

interface FinalscoreMapProps {
  score: number,
  actualLocation: string,
  guessMarkerLatLng: any,
  actualMarkerLatLng: any,
  round: number,
  totalScore: number,
  previousGuesses: any
  pics: any
}

class FinalScoreMap extends Component<FinalscoreMapProps, {
	score: number, 
	zoomLevel: number,
	}> {
	private mapRef: any;
	private locationMarkerRef: any;
	private iconGuessMarker: L.Icon;
	private iconActualMarker: L.Icon;

   	constructor(props: any) {
	    super(props);
	    this.mapRef = React.createRef()
	    this.locationMarkerRef = React.createRef()
	    this.state = {
	    	score: this.props.score ? this.props.score : 0,
	    	zoomLevel: 1,
	    }

	    this.iconGuessMarker = L.icon({
		    iconUrl:guessMarkerImage,
		    iconSize: [40, 50],
		    iconAnchor: [20, 40]
	  	})

	  	this.iconActualMarker = L.icon({
		    iconUrl:actualMarkerImage,
		    iconSize: [40, 50],
		    iconAnchor: [20, 40]
	  	})
	}

  	componentDidUpdate() {
  		setTimeout(() => this.mapRef.current.invalidateSize(false),100);
  	}

  	render() {
  	const bounds = new LatLngBounds([0, 0], [2816, 3328])
  	const maxBounds = new LatLngBounds([0, 0], [2580, 3328])
  	var barWidth =  Math.floor((this.props.totalScore/25000)*100) + '%'

    return (
    	<div>
	    	<div className='scoreOverlay'>
	    	<div className='mapConainter'>
	    	<div className="pointBarBorder">
            <div className="pointBar" style={{width: barWidth, backgroundColor: "green"}}></div>
       		
       		</div>
				<MapContainer
					className='scoreMap'
					whenCreated={ mapInstance => { this.mapRef.current = mapInstance } }
				    center={[2000,1750]}
				    zoom={0}
				    maxZoom={3}
				    crs={L.CRS.Simple}
				    maxBounds={maxBounds}
				    scrollWheelZoom={true}>
				    <ImageOverlay
					  url={genshinFullMap}
					  bounds={bounds}
					  opacity={1}
					  zIndex={100}
					/>

					{this.props.actualLocation == 'Teyvat' ? 
						<Marker 
					      position={this.props.actualMarkerLatLng} 
					      icon={this.iconActualMarker} 
					      title='locationMarker'>
					    </Marker>
					: null }
					{this.props.actualLocation == 'Teyvat' ? 
					    <Polyline
					    	color='yellow'
					    	positions={[this.props.actualMarkerLatLng,this.props.guessMarkerLatLng]}
					    />
					: null }
					{this.props.actualLocation == 'Teyvat' ? 
					    <Marker 
					      position={this.props.guessMarkerLatLng} 
					      icon={this.iconGuessMarker} 
					      title='locationMarker'>
					    </Marker>
					: null }

					{this.props.pics[0].location == 'Teyvat' ? 
				    <Marker 
				      position={[this.props.pics[0].lat,this.props.pics[0].lng]}
				      icon={this.iconActualMarker} 
				      title='locationMarker'>
				    </Marker>
				    : null }
				    {this.props.pics[0].location == 'Teyvat' ? 
				    <Polyline
				    	color='yellow'
				    	positions={[[this.props.pics[0].lat,this.props.pics[0].lng],[this.props.previousGuesses[0][0],this.props.previousGuesses[0][1]]]}
				    />
					: null }
					{this.props.pics[0].location == 'Teyvat' ? 
				    <Marker 
				      position={[this.props.previousGuesses[0][0],this.props.previousGuesses[0][1]]} 
				      icon={this.iconGuessMarker} 
				      title='locationMarker'>
				    </Marker>
				    : null }

				    {this.props.pics[1].location == 'Teyvat' ?
					<Marker 
				      position={[this.props.pics[1].lat,this.props.pics[1].lng]}
				      icon={this.iconActualMarker} 
				      title='locationMarker'>
				    </Marker>
				    : null }
				    {this.props.pics[1].location == 'Teyvat' ?
				    <Polyline
				    	color='yellow'
				    	positions={[[this.props.pics[1].lat,this.props.pics[1].lng],[this.props.previousGuesses[1][0],this.props.previousGuesses[1][1]]]}
				    />
				    : null }
				    {this.props.pics[1].location == 'Teyvat' ?
				    <Marker 
				      position={[this.props.previousGuesses[1][0],this.props.previousGuesses[1][1]]} 
				      icon={this.iconGuessMarker} 
				      title='locationMarker'>
				    </Marker>
				    : null }

 					{this.props.pics[2].location == 'Teyvat' ?
				    <Marker 
				      position={[this.props.pics[2].lat,this.props.pics[2].lng]}
				      icon={this.iconActualMarker} 
				      title='locationMarker'>
				    </Marker>
				    : null }
				    {this.props.pics[2].location == 'Teyvat' ?
				    <Polyline
				    	color='yellow'
				    	positions={[[this.props.pics[2].lat,this.props.pics[2].lng],[this.props.previousGuesses[2][0],this.props.previousGuesses[2][1]]]}
				    />
				    : null }
				    {this.props.pics[2].location == 'Teyvat' ?
				    <Marker 
				      position={[this.props.previousGuesses[2][0],this.props.previousGuesses[2][1]]} 
				      icon={this.iconGuessMarker} 
				      title='locationMarker'>
				    </Marker>
				    : null }

				    {this.props.pics[3].location == 'Teyvat' ?
				    <Marker 
				      position={[this.props.pics[3].lat,this.props.pics[3].lng]}
				      icon={this.iconActualMarker} 
				      title='locationMarker'>
				    </Marker>
				    : null }
				    {this.props.pics[3].location == 'Teyvat' ?
				    <Polyline
				    	color='yellow'
				    	positions={[[this.props.pics[3].lat,this.props.pics[3].lng],[this.props.previousGuesses[3][0],this.props.previousGuesses[3][1]]]}
				    />
				    : null }
				    {this.props.pics[3].location == 'Teyvat' ?
				    <Marker 
				      position={[this.props.previousGuesses[3][0],this.props.previousGuesses[3][1]]} 
				      icon={this.iconGuessMarker} 
				      title='locationMarker'>
				    </Marker>
				    : null }

				</MapContainer>
			</div>
				<div className='roundEndedText'>
					Your final score is {this.props.totalScore} points!
	          	</div>
			</div>
		</div>
		);
  	}
}

export default FinalScoreMap;