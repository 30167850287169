import React, {useEffect, useState, Component, MouseEvent} from 'react';
import { GenshinGeoguessrApi } from './api/genshin.geoguessr.api';
import { PictureDTO } from './api/picture.dto';
import './App.css';
import genshinlogoday from './images/genshinlogo.png';
//import genshinlogosunset from './images/genshinlogosunset.png';
//import genshinlogonight from './images/genshinlogonight.png';
import StartButton from './components/StartButton';

function App() {
  const [pictures] = useState<PictureDTO[]>([])

  return (
    <div className="GenshinMapHome" >
      <img src={genshinlogoday} className="GenshinLogo"/>
      <StartButton/>
    </div>
  );
}

export default App;
